import axios from 'axios'

export class SomaCumulativa {
  static async addSomaCumulativa(ano, aluno_id, turma_id) {
    const info = {
      ano: ano,
      aluno_id: aluno_id,
      turma_id: turma_id
    }
    return axios.post('/matriculaTardia/somaCumulativa', info);
  }
}
