import axios from 'axios'

export class MatriculaTardia {
  static async obtemTodos() {
    return axios.get('/matriculaTardia')
  }
  static async obtemUm(id) {
    return axios.get('/matriculaTardia/' + id)
  }
  static async cadastrar(payload) {
    return axios.post('/matriculaTardia', payload)
  }
  static async alterar(payload) {
    return axios.put('/matriculaTardia/' + payload.id, payload)
  }
  static async remover(id) {
    return axios.delete('/matriculaTardia/' + id)
  }
}
